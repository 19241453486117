/*
  suggested color scheme:
  Dark Blue: #1d262e
  Light Blue: #d9e5ec
  Light Grey: #e8e7ea
  Deep Red: #9e222c

*/

/* google font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&family=VT323&display=swap');

@font-face{
  font-family: 'Avenir';
  src: url('./assets/fonts/Avenir-Light.ttf');
}
@font-face{
  font-family: 'Avenir';
  src: url('./assets/fonts/Avenir-Oblique.otf');
  font-style: italic;
}
@font-face{
  font-family: 'Avenir';
  src: url('./assets/fonts/Avenir-Heavy.otf');
  font-weight: 300;
}
@font-face{
  font-family: 'Avenir';
  src: url('./assets/fonts/Avenir-Medium.otf');
  font-weight: 200;
}
@font-face{
  font-family: 'Avenir';
  src: url('./assets/fonts/Avenir-MediumOblique.otf');
  font-weight: 200;
  font-style: italic;
}
@font-face{
  font-family: 'Didot';
  src: url('./assets/fonts/Didot-Regular.ttf');
}
@font-face{
  font-family: 'Didot';
  src: url('./assets/fonts/Didot-Bold-Italic.ttf');
  font-weight: bold;
  font-style: italic;
}
@font-face{
  font-family: 'Didot';
  src: url('./assets/fonts/Didot-Medium.ttf');
  font-weight: 200;
}
/* layout */
:root {
  --primary: #1aac83;
  --error: #e7195a;
}
body {
  background: #f4f4f4;
  margin: 0;
  color:#fff;
}
.App {
  /* display: flex;
  flex-direction: column; */
  background-color: #ffffff;
  font-family: "Avenir";
}
.pages{
  max-width:100%; /* initially 1400px */
  padding: auto 20px;
  margin: 0 auto;
  min-height: 55.5vh;
}
/* //////////////////// Navbar   ////////////////// */
.navbar {
  background-color: #fefefe;
  background-image: linear-gradient(to bottom, #fefefe 60%, #ffe7e9 );
  /* padding: 10px 10px;      Padding for spacing */
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
}
.navbar .container{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;  /* Dark background color */
  /* padding: 10px 10px;      Padding for spacing */
  
}
.navbar .customlogo {
  color: #ffffff;             /* White text color for logo */
  width: 25%;      
  margin: 1% 0 0;
}
/* .navbar .navlinks-box{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
} */
.navlinks-list {
  display: flex;
  justify-content: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.navitem {
  margin: 0 20%; /* Adjust the spacing between navigation links */
  text-decoration: none; /* Remove underline from links */
  font-size: 1.8rem;
  color:#1d262e; 
}
.navbar  .navlink {
  color:#1d262e;             /* White text color for nav links */
  /* margin-right: 4rem;     Spacing between links */
       /* Larger font size for nav links */
  font-size: 1.6rem;
}
.navbar .navlink:hover {
  color:#9e222c;         /* Light red color on hover */
  text-decoration: none;  /* Remove underline on hover */
}
.navbar .navlink.active {
  color:#9e222c;         /* Light red color when active */
  font-weight: 200;
}

/* //////////////////// Footer ////////////////// */
/* //f8e4dc */
.footer {
  background-color: #ffe7e9; 
  padding: 20px 0;
  text-align: left;
}

.footer-content {
  margin: 30px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer p {
  margin: 0;
}

.footer-icons {
  display: flex;
}

.footer-icons a {
  margin: 10px; /* Adjust as needed */
}

.footer-icons img {
  width: 50px; /* Adjust size of icons as needed */
  max-height: 50px;
}
.footer-icons img:hover {
  background-color: #f00f0f1c;
  border-radius: 25%;
}
/* //////////////////// Home Page ////////////////// */
/* //////// Hero Section //////// */

.hero {
  border-top: #ffffff 4px solid;
  border-bottom: #ffffff 4px solid;
  width: 100%;
  height: 100vh;
  color: #ffffff;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: grid;
  /* padding: 20px; */
  background-image: url('./assets/images/backdrop.png');
  background-size: cover;
  background-position: 50% 15%;;
}
.hero .box {
  background-color: #ffffff2c;
  backdrop-filter: blur(20px);
  padding: 8% 100px;
  border: 4px solid #ffffff77;
  margin: 0% auto;
  /* width: 80%; */
  /* opacity: 0.1; */
}
/*f8fbfd*/
.hero h1 {
  font-size: 3.2em;
  font-weight: 300;
}
.hero p {
  font-size: 1.2em;
  margin: 15px auto 25px;
  font-weight: 200;
}
.hero .shop-now {
  color: #ffffff;
  font-weight: 200;
  text-align: center;
  padding: 10px 50px;
  font-size: 1em;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  border: #ffffffbe solid 2px;
  border-radius: 0px;
  font-style: italic;
  font-weight: 200;
}
.hero .shop-now:hover {
  text-align: center;
  font-size: 1em;
  background-color: #ffffff4f;
  color: #ffffff;
  border: none;
  cursor: pointer;
  text-decoration: none;
}

/* //////// featured/Top Picks //////// */
.featured-products {
  background-image: linear-gradient(to bottom, #fce7df, #ffffff, #ffffff, #ffffff, #ffffff, #ffffff);
  color: #1d262e;
  text-align: center;
  padding: 5%;
}
.featured-products .featured-products-heading{
  font-size: 2.2em;
  font-weight: 300;
}
.featured-products .featured-products-line{
  border-top: 2px solid #1d262e;
  width: 3.2%;
}
.featured-products .see-more {
  font-style: italic;
  text-align: center;
  font-weight: 200;
  padding: 10px 60px;
  font-size: 1em;
  background-color: transparent;
  color:#9e222c;
  border: none;
  cursor: pointer;
  /* text-decoration: none; */
  border: #9e222c solid 2px;
}
.featured-products .see-more:hover {
  text-decoration: none;
  background-color: #9e222c;
  color: #ffffff;
  cursor: pointer;
}
.featured-products .featured-products-container {
  /* display: block;
  justify-content: center;
  align-items: center; */
  justify-content: center;
  align-items: center;
  display: grid;
}
/*/////////////////// Product Card /////////////////*/
.preview-product-card {
  margin: auto auto 40px;
}
.preview-product-image-card {
  max-width: 98%;
  border-radius: 20px;
  box-shadow: 10px 5px 5px #85091341;
  margin: 20px auto 30px; 
  /* border: 5px solid #ffffff; */
  /* display: grid;
  align-items: center; /* Vertically center 
  justify-content: center;
  text-align: center; */
  /* background-color: #f3f2f2; */
  /* width: 400px;
  height: 400px; */
  /* padding: 20px auto 30px; */
}
.painting-card-images {
  border-radius: 20px;
  max-width: 100%;
  /* width: auto; */
  /* overflow: hidden;
  border-radius: 10px; */
}
.preview-product-card  .Title {
  margin: auto;
  font-size: 1.6em; 
}
.preview-product-card  p {
  /* font-family: 'Didot';
  font-weight: bold; */
  font-style: italic;
  color:#9e222c;
  font-size: 1em;
  margin-bottom: 0px;
  margin-top: 2px;
}

/* //////// About me preview Section //////// */
.about-me {
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  padding: 50px auto 50px;
  font-size: 1.5em;
}
.about-me .Aboutme-title{
  font-size: 1.8em;
  font-weight: 300;
}
.Aboutme-quote{
  max-width: 90%;
  margin-bottom: 1rem;
}
.Aboutme-info{
  text-align: center;
  max-width: 70%;
}
.divider{
  border-top: 2px solid #1d262e;
  width: 3.2%;
}
.Follow{
  /* text-decoration: none; */
  width: 100%;
  padding: 20px 0px 100px;
  background-image: linear-gradient(to bottom, #ffffff , #fff6f7 );
}
.Follow .insta{
  text-decoration: none;
  color: #9e222c;
}
/*//////////////////////// Product Page ////////////////////////*/
.products-page {
  background-image: linear-gradient(to bottom, #ffffff, #fff6f7);
  color: #1d262e;
  text-align: center;
  padding: 5% 10%;
  justify-items: center;
  align-items: center;
}
.products-page .products-page-container {
  display: block;
  justify-content: center;
  align-items: center;
  /* display: inline-grid; */
  /* background-color: #000000; */
}
.products-page .products-page-heading{
  font-size: 2.8em;
  font-weight: 300;
}
.products-page .products-page-line{
  border-top: 2px solid #1d262e;
  width: 3.2%;
  padding-bottom: 1%;
}
/*  Product Card */
.product-card {
  margin: auto auto 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  /* padding: 0 50px; */
}
.product-card-image {
  margin: 20px auto;
  max-width: 100%;
}
.product-card-description {
  /* padding: 20px; */
  text-align: center;
  min-width: 100%;
}
.product-card  .Title {
  /* margin: 0; */
  font-size: 1.8em; 
  font-weight: 200;
}
.product-card  p {
  font-style: italic;
  color:#1d262e;
  font-size: 1em;
  margin: 10px auto;
  max-width: 80%;
}
.product-card  .red {
  font-style: italic;
  color:#9e222c;
  font-size: 1em;
  margin: 10px auto;
  max-width: 80%;
}
.product-card button{
  margin-top: 10px;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  background-color: #9e222c;
  color: white;
  border-radius: 10px;
}
.product-card button:hover{
  background-color: #ff7d8e67;
  color: #9e222c;
  border-radius: 10px;
}

/* CustomOrderBanner.css */

.custom-order-banner {
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  background-color: #fdfdfd;
  border: #9e222c1a 5px solid;
  backdrop-filter: blur(20px);
  padding: 5% 100px;
  margin: 5% auto 0;
}

.custom-order-banner h2 {
  font-weight: 200;
  color: #9e222c;
  margin-bottom: 30px;
  font-style: italic;
}

.custom-order-banner p {
  font-style: italic;
  color:#1d262e;
  font-size: 1.4em;
  margin-bottom: 10px auto;
}

.custom-order-button {
  background-color: #9e222c;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  border: none;
}

.custom-order-button:hover {
  background-color: #ff7d8e67;
  color: #9e222c;
}

/* //////////////////// Pagination ////////////////// */
/* Pagination container */
.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  margin-top: 30px;
}

/* Pagination links */
.pagination a {
  text-decoration: none;
  font-weight: 200;
  color: #fdeff0 !important;
  padding: 10px 15px;
  margin: 0 5px;
  transition: background-color 0.3s;
  background-color: #9e222c;
  border-radius: 10px;
}

/* Active page link */
.pagination .active a {
  /* background-color: #ffffff2c; */
  backdrop-filter: blur(20px);
  background-color: #ff7d8e67;
  color: #9e222c !important;
}

/* Hover effect */
.pagination a:hover {
  background-color: #ff7d8e67;
  color: #9e222c !important;
  text-decoration: none;
}

/* Previous and next button styles */
.prev-button,
.next-button {
  display: inline-block;
  margin: 0 5px;
}
/* disabled button styles
.prev-button.disabled,
.next-button.disabled {
  pointer-events: none;
  color: #000000;
} */
/* //////////////////// Shop Page ////////////////// */
.Shop {
  position: relative; /* Ensure that the pseudo-element is positioned correctly */
  border-top: #ffffff 4px solid;
  border-bottom: #ffffff 4px solid;
  width: 100%;
  color: #ffffff;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: grid;
  overflow: hidden;
}

.Shop::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('./assets/images/sunrise.jpg');
  background-size: cover;
  background-position: 50% 15%;
  filter: blur(5px); /* Adjust the blur amount as needed */
  z-index: 0; /* Ensure the pseudo-element stays behind the content */
}


.Shop > * {
  position: relative;
  z-index: 1;
}
.Shop .shop-container {
  background-color: #f00f0f1c;
  backdrop-filter: blur(30px);
  color:#ffffffda;
  padding: 5% 100px;
  border: 3px solid #ffffffda;
  margin: 13% auto;
  width: 100%;
  border-radius: 50px;
  /* opacity: 0.1; */
}
/*f8fbfd*/
.Shop .Title {
  font-size: 3.2em;
  font-weight: 300;
  font-style: italic;
  margin:0px 0px 15px;
  border-radius: 55px;
}
.Shop p {
  font-size: 1.2em;
  margin:0px auto;
  font-weight: 200;
  font-style: italic;
}
.Shop img {
  margin: 20px 50px 0px;
  max-height: 50px;
  background-color: #f00f0f1c;
  border-radius: 25%;
}
.Shop img:hover {
  background-color: transparent;
  border-radius: 25%;
}

/*//////////////////////// Checkout Page ////////////////////////*/
.Checkout-page {
  color: #1d262e;
  text-align: center;
  padding: 4% 10%;
  max-width: 100%;
}
.Checkout-page .Checkout-page-container {
  justify-content: center;
  align-items: center;
}
.Checkout-page .Checkout-page-heading{
  font-size: 2.8em;
  font-weight: 200;
}
.Checkout-page .Checkout-page-line{
  border-top: 2px solid #1d262e;
  width: 3.2%;
  padding-bottom: 1%;
}
/*  Product Card */
.Checkout-card {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 20px 20px 20px;
  margin: 20px;
  justify-content: center;
  border: 1px solid #ddd;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* min-width: 90%; */
  /* padding: 0 50px; */
  width: 100%;
}
.Checkout-page .Checkout-page-image {
  margin: 10px auto 25px;
  max-width: 100%;
}
.Checkout-page .shop-now {
  color: #ddd;
  font-weight: 200;
  text-align: center;
  padding: 10px 50px;
  margin-top: 0px;
  font-size: 1em;
  background-color: #9e222c;
  cursor: pointer;
  text-decoration: none;
  border: #ddd solid 2px;
  font-style: italic;
  font-weight: 200;
}
.Checkout-page .shop-now:hover {
  color: #9e222c;  
  background-color: #ddd;
  border: #9e222c solid 2px;
}
.Checkout-page .Checkout-page-paragraph{
  color:#1d262e;
  font-size: 1.4em;
  margin: 20px auto 15px;
  max-width: 80%;
}
.Checkout-card-image-box{
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-shrink: 0; Prevent the image box from shrinking */
  margin: 20px 10px;
  padding-top: 100%;
  overflow: hidden; 
  max-width: 90%;
}
.Checkout-card-image-box img {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 90%;
  height: auto;
  transform: translate(-50%, -50%);
  object-fit: cover; /* Ensures the image covers the area without distortion */
}
.Checkout-card-image {
  /* margin: 20px auto;*/
  aspect-ratio: 8/9; /* Maintain aspect ratio */
  max-width: 100%;
  border-radius: 10px;
}
.Checkout-card-description {
  /* background-color: #e7c6c6; */
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  /* max-width: 90%; */
  /* flex: 1; */
  /* width: 300px; */
  /* max-width: 90%; */
}
.Checkout-card  .Title {
  /* margin: 0; */
  font-size: 1.8em; 
  font-weight: 200;
}
.Checkout-card  p {
  font-style: italic;
  color:#1d262e;
  font-size: 0.9em;
  margin: 2px auto;
  /* max-width: 80%; */
}
.Checkout-card  .red {
  font-style: italic;
  color:#9e222c;
  font-size: 1em;
  margin: 5px auto;
  /* max-width: 80%; */
}
.Checkout-card button {
  margin-top: 10px;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
}
.checkout-button{
  margin-top: 10px;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  background-color: #9e222c;
  color: white;
}
.Checkout-Form-Heading{
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.Checkout-Form-Heading h2 {
  font-size: 1.5em;
  width: 100%;
}
.Checkout-Form-Heading .shipping{
  /* border-right: #1d262e 1px solid; */
  background-color: #f0f0f0;
  border-right: none;
  border-radius: 15px 0 0 15px;
  padding: 10px 5px 10px;
}
.Checkout-Form-Heading .shipping.active{
  color: #9e222c; /* Active text color */
  border-right: #9e222c 1px solid;
  background-color: #ffe7e9;
  font-weight: 200;
}
.Checkout-Form-Heading .payment{
  /* border: #1d262e 1px solid; */
  border-left: #1d262e 1px solid;
  background-color: #f0f0f0;
  border-left: none;
  border-radius: 0 15px 15px 0;
  padding: 10px 5px 10px;
}
.Checkout-Form-Heading .payment.active{
  color: #9e222c;
  border-left: #9e222c 1px solid;
  background-color: #ffe7e9;
  font-weight: 200;
}
.checkout-total{
  font-weight: 200;
  margin: 10px auto;
  width: 95%;
}
.Checkout-card .remove-from-cart {
  background-color: #9e222c;
  color: white;
  border-radius: 15px;
}
.Checkout-card .remove-from-cart:disabled {
  background-color: #4a4a4a;
}
.Checkout-Form{
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 30px;
  margin: 20px 0;
}
.label-custom {
  font-weight: 200;
  text-align: left;
  display: block; /* Ensures label behaves as a block element */
}
.input-custom {
  display: block;
  width: 100%; /* Make sure the input takes the full width */
  margin-bottom: 10px; /* Space between input fields */
  padding: 8px; /* Adds some padding inside the input */
  border-radius: 8px; /* Adjust this value for more or less rounded corners */
  border: 1px solid #ccc; /* A subtle border to define the input field */
  box-sizing: border-box;
}
.Address-Submit-Button{
  background-color: #9e222c;
  border-radius: 15px;
  color: white;
  padding: 10px 25px;
  border: none;
  cursor: pointer;
  margin-top: 10px;
}
/* //////////////////// Inventory Page ////////////////// */
.inventory {
  align-items: center;
  display: flex;
  width: 80%;
  gap: 20px;
  margin: 20px;
}
/* Inventory Card */
.inventory-delete {
  color: #e71919;
}
.inventory-delete:hover {
  color: #ffffff;
  background-color: #f05959;
  border-color: #ffffff;
  cursor: pointer;
}
.inventory-card{
  width: 100%;
  padding: 20px;
  color: #333;
  border: 2px solid #000000;
}
.inventory-card .inventory-card-image{
  /* width: 400px; /* Adjust width as needed */
  /* height: 300px; Adjust height as needed */
  overflow: hidden;
  position: relative;
  border: 1px solid #ffffff; /* Optional border for visual clarity */
  border-radius: 8px; /* Optional rounded corners */
}
.inventory-card .inventory-card-image img{
    width: 100%;
    height: 100%;
    top: 0 !important;
    left: 0;
    object-fit: cover; /* Ensures the image covers the container without distortion */
    position: absolute;
}
.add-item-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  padding: 2%;
  border: #000000 2px solid;
}
/* for smaller screens */
@media (max-width: 992px) {
  .navlink {
    font-size: 1.2rem; /* Adjust font size for medium screens */
  }
  .navbar  .navlink {
    font-size: 1.2rem;
  }
  .navitem {
    margin: 0 5%;
   }
  .hero .box {
    margin: 0% auto;
    backdrop-filter: blur(20px);
    padding: 7% 1%;
    width: 90%;
    font-size: 0.9em;
  }
  .preview-product-image-card{
    max-width: 330px;
  }
  .painting-card-images{
    max-width: 330px;
  }
  .about-me .Aboutme-title{
    font-size: 1.6em;
    font-weight: 200;
  }
  .footer-content {
    margin: 10px 15px;
  }
  .footer-icons {
    display: grid;
  }
  .footer-icons a {
    margin: 0; 
  }
  .footer-icons img {
    width: 38px; 
    margin-bottom: 5px;
  }
  .product-card {
    margin: auto auto 40px;
    display: grid;
    justify-content: center;
    align-items: center;
  }
  .product-card-image {
    max-width: 340px;

  }
  .Shop .shop-container {
    padding: 4% 40px;
    border-radius: 50px;
    width: 85%;
  }

  .Shop img {
    margin: 20px 30px 15px;
  }
  .custom-order-banner {
    padding: 25px;
    /* min-width: 90%;/ */
    /* padding: 5% 100px; */
    margin: 10% auto 0;
  }

  .custom-order-banner h2 {
    font-size: 1.6em;
  }
  
  .custom-order-banner p {
    font-size: 1.2em;
  }
}
/* @media (max-width: 992px) {
  .navlink {
    font-size: 1.2rem;
  }
} */